$(function() {
    //menu doctor
    $('ul.menu > li > a').click(function(){
        if ($(this).hasClass('on')) {
        	$('ul.menu li a').removeClass("on");
        }
        else {
        	$('ul.menu li a').removeClass("on");
        	$(this).toggleClass("on");
        }
    });
    $('ul.menu > li > ul > li > a').click(function(){
        if ($(this).hasClass('on')) {
        	$('ul.menu > li > ul > li > a').removeClass("on");
        }
        else {
        	$('ul.menu > li > ul > li > a').removeClass("on");
        	$(this).toggleClass("on");
        }
    });
    $(window).resize(function(){
    	$('ul.menu li a').removeClass("on");
    });
    //formularios
    $('.show-video').click(function(){
        $('#modal-video').addClass("on");
        $('body').addClass('over');
    });
    $('.close-modal , .bg-modal').click(function(){
        $('.cover-modal').removeClass("on");
        $('body').removeClass('over');
    });
    
});